

.tablist-container {
	display: flex;
	flex-direction: column;

	position: relative;
	width: 100%;
	height: 100%;

	background-color: var(--color-background);
}

.tablist-header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	gap: 12px;
	padding: 8px 16px;

	width: 100%;
}

.tablist-tab {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	height: 48px;
	width: auto;
	padding: 8px 16px;
	gap: 16px;

	cursor: pointer;
	background-color: var(--color-tab);
	color: var(--color-text);
	border-radius: 8px;

	user-select: none;
}

.tablist-tab.active {
	background-color: var(--color-tab-active);
}

.tablist-tab:hover {
	background-color: var(--color-tab-hover);
}

.tablist-tab:active {
	background-color: var(--color-tab-active);
}

.tablist-tab.right {
	margin-left: auto;
}

.tablist-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	gap: 8px;

	height: calc(100% - 50px);
	width: 100%;

	padding: 8px 16px 16px 16px;
}

.tablist-content:not(.active) {
	display: none;
}

.tablist-content-inner {
	display: block;
	position: relative;

	height: 100%;
	width: 100%;

	overflow-x: clip;
	overflow-y: clip;

	border-radius: 8px;

	background-color: #fff;
}

.tablist-content-inner .loading-screen {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	z-index: 10;
	background-color: rgba(255, 255, 255, 0.5);
	animation: background-loading 2s infinite;
	cursor: progress;
}

.tablist-content-inner .loading-screen sl-spinner {
	font-size: 32px;
}

@keyframes background-loading {
	0% {
		background-color: rgba(255, 255, 255, 0.5);
	}
	50% {
		background-color: rgba(255, 255, 255, 0.8);
	}
	100% {
		background-color: rgba(255, 255, 255, 0.5);
	}
}

