.App * {
	box-sizing: border-box;
}

.App {
	height: 100vh;
	width: 100vw;
	position: relative;
}

.container {
	position: relative;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.container>div {
	height: 100%;
	width: 100%;
	min-width: 0;
}